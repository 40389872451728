import { useState } from "react";
import { PriceData } from "./token-swap";
import { useNavigate, useNavigation } from "@remix-run/react";
import { classNames, roundToDecimals } from "~/lib/util";

type Props = {
	priceData: PriceData;
};

export default function TokenPrice({ priceData }: Props) {
	const naviation = useNavigation();
	const [direction, setDirection] = useState(true);

	function onSwitch() {
		setDirection(!direction);
	}

	return priceData.inputAmount > 0 ? (
		<button type="button" onClick={onSwitch} className={classNames(naviation.state == "submitting" ? "animate-pulse rounded bg-neutral-200" : "", "text-right font-mono text-sm")}>
			{direction ? (
				<>
					1 {priceData.inputToken.symbol} = {roundToDecimals(priceData.outputAmount / priceData.inputAmount)} {priceData.outputToken.symbol}
				</>
			) : (
				<>
					1 {priceData.outputToken.symbol} = {roundToDecimals(priceData.inputAmount / priceData.outputAmount)} {priceData.inputToken.symbol}
				</>
			)}
		</button>
	) : null;
}
