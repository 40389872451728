import { Route, Token } from "~/lib/types";
import { classNames, getIconUrl, prettyExchangeName, roundToDecimals } from "~/lib/util";

export interface RoutesTableProps {
	tokens: Token[];
	routes: Route[];
	inputAmount: number;
	outputAmount: number;
	inputToken: Token;
	outputToken: Token;
	priceImpact: number;
	fee: number;
}

export default function RoutesTable({ tokens, routes, inputAmount, outputAmount, inputToken, outputToken, priceImpact, fee }: RoutesTableProps) {
	routes.sort((a: Route, b: Route) => {
		return a.tokensOut < b.tokensOut ? 1 : -1;
	});

	return (
		<>
			{routes.length > 0 ? (
				<table className="w-full border-spacing-8 overflow-hidden rounded-lg text-sm text-white shadow-lg">
					<tbody className="_backdrop-blur space-y-3 bg-white/30">
						{routes.map((route, index) => {
							return (
								<tr key={route.pools[0].type + route.tokensOut}>
									<td className={classNames(index == 0 ? "pt-3" : "", index == routes.length - 1 ? "pb-3" : "", "py-1 pl-3 pr-1 font-mono text-xs")}>
										{roundToDecimals((route.tokensOut / outputAmount) * 100, 1)}%
									</td>

									<td className={classNames(index == 0 ? "pt-3" : "", index == routes.length - 1 ? "pb-3" : "", "px-1 py-1")}>
										{route.pools.map((pool, index) => (
											<span className="flex items-center" key={`${pool.type}-${pool.baseToken}`}>
												{index == 1 ? (
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-2 h-3 w-3">
														<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
													</svg>
												) : null}
												<span className="relative mr-2 h-4 w-6">
													<img src={getIconUrl(tokens.filter((t) => t.address == pool.quoteToken)[0].icon_url, 16)} className="absolute left-2 top-0 h-4 w-4 rounded-full" />
													<img src={getIconUrl(tokens.filter((t) => t.address == pool.baseToken)[0].icon_url, 16)} className="absolute left-0 top-0 h-4 w-4 rounded-full" />
												</span>
												<span className="font-optical-14-550 tracking-astro">{prettyExchangeName(pool.type).name}</span>
											</span>
										))}
									</td>
									<td className={classNames(index == 0 ? "pt-3" : "", index == routes.length - 1 ? "pb-3" : "", "hidden px-1 py-1 text-right font-mono text-xs md:table-cell")}>
										{roundToDecimals(route.tokensIn, 2)} {inputToken.symbol}
									</td>
									<td className={classNames(index == 0 ? "pt-3" : "", index == routes.length - 1 ? "pb-3" : "", "py-1 pl-1 pr-3 text-right font-mono text-xs")}>
										{roundToDecimals(route.tokensOut)} {outputToken.symbol}
									</td>
								</tr>
							);
						})}

						{/* <tr key="fee">
							<td className="px-3 py-1 pb-3 font-mono text-xs">{+fee > 0 ? "-0.1%" : "Free"}</td>

							<td className="px-3 py-1 pb-3">
								<span className="flex items-center">
									<span className="relative mr-2 block h-4 w-6">
										<img src={tokens.filter((t) => t.address == routes[0].pools[routes[0].pools.length - 1].quoteToken)[0].icon_url} className="absolute left-2 top-0 h-4 w-4 rounded-full" />
									</span>
									<span className="font-optical-14-550 tracking-astro">Astrolescent fee</span>
								</span>
							</td>
							<td className="hidden px-3 py-1 pb-3 text-right font-mono text-xs md:table-cell">&nbsp;</td>
							<td className="px-3 py-1 pb-3 text-right font-mono text-xs">
								{+fee > 0 ? `-${(Math.round(fee * 100) / 100).toLocaleString()}` : "0"} {outputToken.symbol}
							</td>
						</tr> */}
					</tbody>
					<tfoot className="_backdrop-blur bg-black/30 p-3 text-xs">
						<tr>
							<td colSpan={2} className="px-3 py-3 text-left font-mono">
								100%
							</td>
							<td className="hidden px-3 py-3 text-right font-mono md:table-cell">
								{roundToDecimals(inputAmount, 2)} {inputToken.symbol}
							</td>
							<td className="px-3 py-3 text-right font-mono max-md:grow">
								{roundToDecimals(outputAmount)} {outputToken.symbol}
							</td>
						</tr>
					</tfoot>
				</table>
			) : null}
		</>
	);
}
