import { Fragment, useEffect, useState } from 'react';
import { Combobox, Dialog, Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, DocumentPlusIcon, FolderIcon, FolderPlusIcon, HashtagIcon, MagnifyingGlassIcon, TagIcon } from '@heroicons/react/20/solid';
import { ASTRL_RESOURCE_ADDRESS, DFP2_RESOURCE_ADDRESS, FAV_TOKENS, XRD_RESOURCE_ADDRESS, classNames, getIconUrl, onIconError, prettyValue } from '~/lib/util';
import { Token } from '~/lib/models/tokens';

type Props = {
   name: string;
   tokens: Token[];
   balances?: any[],
   selected: Token;
   onSelected: (value: Token) => void;
};

const MAX_DEFAULT_TOKEN_COUNT = 25;

export default function TokenSelector({ name, tokens, balances, selected, onSelected }: Props) {
   const [displayTokens, setDisplayTokens] = useState(tokens);
   const [searchTerm, setSearchTerm] = useState('');
   const [query, setQuery] = useState('');
   const [open, setOpen] = useState(false);

   function onSearch(term: string) {
      setSearchTerm(term);
   }

   function sortTokens(a: Token, b: Token) {
      if (!balances) {
         return 0;
      }

      const balanceA = parseFloat(balances.find((bal) => bal.address == a.address)?.amount || 0);
      const balanceB = parseFloat(balances.find((bal) => bal.address == b.address)?.amount || 0);

      const priceA = balanceA * a.tokenPriceUSD;
      const priceB = balanceB * b.tokenPriceUSD;

      if (priceA < priceB) {
         return 1;
      } else if (priceA > priceB) {
         return -1;
      } else if (balanceA < balanceB) {
         return 1;
      } else if (balanceA > balanceB) {
         return -1;
      } else if (a.tvl < b.tvl) {
         return 1;
      } else if (a.tvl > b.tvl) {
         return -1;
      }

      return 0;
	}

   useEffect(() => {
      // tokens.sort(sortTokens);

      setDisplayTokens(tokens.slice(0, MAX_DEFAULT_TOKEN_COUNT));
   }, [tokens]);

   useEffect(() => {
      if (!balances) {
         return;
      }

      const copyTokens = [...tokens];

      copyTokens.sort(sortTokens);

      setDisplayTokens(copyTokens.slice(0, MAX_DEFAULT_TOKEN_COUNT));
   }, [balances]);

   useEffect(() => {
      if (!open) {
         setQuery('');
      }
   }, [open]);

   useEffect(() => {
      let regExSearch = new RegExp(searchTerm, 'i');

      // Reset search and display all tokens
      if (!searchTerm) {
         tokens.sort(sortTokens);
         setDisplayTokens(tokens.slice(0, MAX_DEFAULT_TOKEN_COUNT));
         return;
      }

      // Filter tokens by search term
      let filteredTokens = [];
      for (let token of tokens) {
         if (token.symbol && token.symbol.match(regExSearch)) {
            filteredTokens.push(token);
            continue;
         }

         if (token.name && token.name.match(regExSearch)) {
            filteredTokens.push(token);
         }
      }

      filteredTokens.sort(sortTokens);
      setDisplayTokens(filteredTokens);
   }, [searchTerm]);

   return (
		<>
			<div className="flex w-40 flex-none justify-end">
				<Listbox name={name} value={selected} onChange={onSelected}>
					<>
						<div className="">
							<Listbox.Button onClick={() => setOpen(true)} className="flex items-center rounded-full bg-black px-3 py-2 text-white">
								<span className="flex items-center">
									<img src={getIconUrl(selected.icon_url, 24)} onError={(evt) => onIconError(evt)} alt="" className="h-6 w-6 rounded-full" />
									<span className="font-sm font-optical-24-550 tracking-astro ml-2 block">{selected.symbol}</span>
								</span>
								<span className="pointer-events-none ml-3">
									<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
								</span>
							</Listbox.Button>

							<Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
								<Dialog as="div" className="z-[1001] relative" onClose={setOpen}>
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="fixed inset-0 backdrop-blur-lg transition-opacity" />
									</Transition.Child>

									<div className="fixed inset-0 h-screen overflow-y-auto">
										<Transition.Child
											as={Fragment}
											enter="ease-out duration-300"
											enterFrom="opacity-0 scale-95"
											enterTo="opacity-100 scale-100"
											leave="ease-in duration-200"
											leaveFrom="opacity-100 scale-100"
											leaveTo="opacity-0 scale-95"
										>
											<Dialog.Panel className="transform divide-y divide-astro divide-opacity-10 overflow-hidden bg-black/50 text-white ring-1 ring-astro ring-opacity-5 transition-all sm:rounded-lg sm:bg-white sm:text-black sm:shadow-lg md:mx-auto md:mt-12 md:max-w-md">
												<div className="relative">
													<MagnifyingGlassIcon className="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-black/40" aria-hidden="true" />
													<input
														type="search"
														value={searchTerm}
														onChange={(evt) => onSearch(evt.target.value)}
														autoFocus
														autoCorrect="false"
														onClick={(evt) => evt.stopPropagation()}
														onFocus={(evt) => evt.stopPropagation()}
														placeholder="Search..."
														className="h-10 w-full border-none bg-transparent pl-12 pr-3 placeholder-neutral-400"
													/>
												</div>
												<Listbox.Options static className="max-h-max scroll-py-2 divide-y divide-neutral-500 divide-opacity-50 overflow-y-auto md:max-h-[30rem]">
													<li className="grid grid-cols-2 gap-2 p-2 md:grid-cols-4">
														{FAV_TOKENS.map((fav) => {
															const token = tokens.find((t) => t.address == fav);

															if (!token) {
																return null;
															}

															return (
																<Listbox.Option
																	onClick={() => setOpen(false)}
																	key={token.address + "-fav"}
																	value={token}
																	className="relative cursor-default select-none rounded-md border p-2 px-2 sm:hover:bg-neutral-200"
																>
																	{({ selected }) => (
																		<>
																			<div className="flex items-center">
																				<img src={getIconUrl(token.icon_url, 24)} onError={(evt) => onIconError(evt)} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
																				<div className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block")}>
																					<p className="name font-optical-24-550 tracking-astro text-xs">{token.symbol}</p>
																				</div>
																			</div>
																		</>
																	)}
																</Listbox.Option>
															);
														})}
													</li>

													<li className="p-2">
														{displayTokens.map((token) => {
															let tokenBalance = "";
															let tokenPrice = "";

															if (balances) {
																const balance = balances.find((b) => b.address == token.address);

																if (balance) {
																	tokenBalance = prettyValue(balance.amount, false);
																	tokenPrice = (Math.round(balance.amount * token.tokenPriceUSD * 100) / 100).toLocaleString();
																}
															}

															return (
																<Listbox.Option
																	onClick={() => setOpen(false)}
																	key={token.address}
																	value={token}
																	className="relative cursor-default select-none py-2 pl-3 pr-9 sm:hover:bg-neutral-200"
																>
																	{({ selected }) => (
																		<>
																			<div className="flex items-center justify-between">
																				<div className="flex items-center justify-between">
																					<img src={getIconUrl(token.icon_url, 24)} onError={(evt) => onIconError(evt)} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
																					<div className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>
																						<p className="font-optical-24-550 tracking-astro text-sm">{token.name || "Unknown"}</p>
																					</div>
																				</div>
																				<div className="name font-optical-24-550 tracking-astro text-right font-mono text-xs sm:text-black/50">
																					<p>
																						{tokenBalance} {token.symbol}
																					</p>
																					{tokenPrice ? <p>${tokenPrice}</p> : null}
																				</div>
																			</div>
																		</>
																	)}
																</Listbox.Option>
															);
														})}
													</li>
												</Listbox.Options>
											</Dialog.Panel>
										</Transition.Child>
									</div>
								</Dialog>
							</Transition.Root>
						</div>
					</>
				</Listbox>
			</div>
		</>
	);
}
